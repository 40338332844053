import check_webp_feature from "./src/components/common/ggfx-client/module/webp-checker";
check_webp_feature();

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Match any character following the /job-search/ and /page-*/ so we detect if load more was used
const pageRegex = /\bjob-search\b.*?\bpage-\b.*?$/gm;

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(pageRegex)) {
        return false;
    }
    return true;
};