// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-member-js": () => import("./../../../src/pages/about/contact-member.js" /* webpackChunkName: "component---src-pages-about-contact-member-js" */),
  "component---src-pages-about-our-clients-js": () => import("./../../../src/pages/about/our-clients.js" /* webpackChunkName: "component---src-pages-about-our-clients-js" */),
  "component---src-pages-agency-mentors-index-js": () => import("./../../../src/pages/agency-mentors/index.js" /* webpackChunkName: "component---src-pages-agency-mentors-index-js" */),
  "component---src-pages-apply-for-job-js": () => import("./../../../src/pages/apply-for-job.js" /* webpackChunkName: "component---src-pages-apply-for-job-js" */),
  "component---src-pages-candidates-refer-a-candidate-js": () => import("./../../../src/pages/candidates/refer-a-candidate.js" /* webpackChunkName: "component---src-pages-candidates-refer-a-candidate-js" */),
  "component---src-pages-candidates-upload-your-cv-js": () => import("./../../../src/pages/candidates/upload-your-cv.js" /* webpackChunkName: "component---src-pages-candidates-upload-your-cv-js" */),
  "component---src-pages-employers-post-jobs-js": () => import("./../../../src/pages/employers/post-jobs.js" /* webpackChunkName: "component---src-pages-employers-post-jobs-js" */),
  "component---src-pages-employers-refer-an-employer-js": () => import("./../../../src/pages/employers/refer-an-employer.js" /* webpackChunkName: "component---src-pages-employers-refer-an-employer-js" */),
  "component---src-pages-employers-register-js": () => import("./../../../src/pages/employers/register.js" /* webpackChunkName: "component---src-pages-employers-register-js" */),
  "component---src-pages-help-and-advice-blogs-js": () => import("./../../../src/pages/help-and-advice/blogs.js" /* webpackChunkName: "component---src-pages-help-and-advice-blogs-js" */),
  "component---src-pages-help-and-advice-candidate-guide-js": () => import("./../../../src/pages/help-and-advice/candidate-guide.js" /* webpackChunkName: "component---src-pages-help-and-advice-candidate-guide-js" */),
  "component---src-pages-help-and-advice-employer-guide-js": () => import("./../../../src/pages/help-and-advice/employer-guide.js" /* webpackChunkName: "component---src-pages-help-and-advice-employer-guide-js" */),
  "component---src-pages-help-and-advice-index-js": () => import("./../../../src/pages/help-and-advice/index.js" /* webpackChunkName: "component---src-pages-help-and-advice-index-js" */),
  "component---src-pages-help-and-advice-industry-news-js": () => import("./../../../src/pages/help-and-advice/industry-news.js" /* webpackChunkName: "component---src-pages-help-and-advice-industry-news-js" */),
  "component---src-pages-job-search-js": () => import("./../../../src/pages/job-search.js" /* webpackChunkName: "component---src-pages-job-search-js" */),
  "component---src-pages-share-to-friend-js": () => import("./../../../src/pages/share-to-friend.js" /* webpackChunkName: "component---src-pages-share-to-friend-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-agency-category-js": () => import("./../../../src/templates/agencyCategory.js" /* webpackChunkName: "component---src-templates-agency-category-js" */),
  "component---src-templates-agency-details-js": () => import("./../../../src/templates/agencyDetails.js" /* webpackChunkName: "component---src-templates-agency-details-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-job-details-js": () => import("./../../../src/templates/jobDetails.js" /* webpackChunkName: "component---src-templates-job-details-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

